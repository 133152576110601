import React from 'react';
import { Link } from 'gatsby';

import Layout from '../modules/AppShell/Layout/Layout';
import SEO from '../components/Seo';
import NewsList from '../modules/News/NewsList/NewsList';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <NewsList />
  </Layout>
)

export default IndexPage
