import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Wrapper } from './styles';
import NewsCard from '../NewsCard/NewsCard';

const NewsList = () => {
  const { cms } = useStaticQuery(
    graphql`
      query {
        cms {
          newses {
            title
            description
            cover
            id
            author {
              firstName
              lastName
              avatar
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper
      container
      item
      spacing={2}
      justify="space-evenly"
      alignItems="flex-start"
    >
      {
        cms.newses.map(news => (
          <NewsCard news={news} key={news.id}/>
        ))
      }
    </Wrapper>
  )
}

export default NewsList;
