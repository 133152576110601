import styled from 'styled-components';
import { flexbox } from '@material-ui/system';
import { Grid as MuiGrid, Card as MuiCard, CardMedia as MuiCardMedia } from '@material-ui/core';

export const Grid = styled(MuiGrid)`
`;

export const CardMedia = styled(MuiCardMedia)`
  height: 140px;
`;

export const Card = styled(MuiCard)`
  ${flexbox}
`;