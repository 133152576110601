import React from 'react';
import { CardActionArea, CardContent, CardHeader, Typography, Avatar} from '@material-ui/core';
import * as S from './styles';

const NewsCard = ({ news }) => {
  return (
    <S.Grid
      item
      container
      justify="center"
      sm={4}
    >
      <CardActionArea>
        <S.Card flexGrow={1}>
          <S.CardMedia
            image={news.cover}
            title={news.title}
          />
          <CardContent>
            <Typography 
              gutterBottom 
              variant="h5"
              component="h2"
            >
              {news.title}
            </Typography>
            <Typography component="p">
              {news.description}
            </Typography>
          </CardContent>
          <CardHeader
            avatar={
              <Avatar 
                aria-label="Author" 
                alt={`${news.author.firstName} ${news.author.lastName}`}
                src={news.author.avatar}
              />
            }
            title={`${news.author.firstName} ${news.author.lastName}`}
            subheader="September 14, 2016"
          />
        </S.Card>
      </CardActionArea>
    </S.Grid>
  )
}

export default NewsCard;
